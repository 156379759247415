import { useEffect, useState } from 'react'
import { FormControl, TextField } from '@material-ui/core'

const NMVPipelineBravosDateInputWidget = (props) => {
    const { onChange, formContext, id } = props
    const [date, setDate] = useState('')
    // Extract index from id (e.g., "root_run_version_0_generator" -> 0)
    const runVersionIndex = parseInt(id.split('_')[3], 10)

    const handleChange = (event) => {
        setDate(event.target.value)
    }

    useEffect(() => {
        if (
            formContext &&
            formContext.run_version &&
            formContext.run_version[0] &&
            formContext.run_version[0].modelDateBravos &&
            date === ''
        ) {
            const timeout = setTimeout(
                () => {
                    setDate(formContext.run_version[0].modelDateBravos)
                },
                runVersionIndex * 1 + 1
            )

            return () => clearTimeout(timeout)
        }
    }, [formContext, date, runVersionIndex])

    useEffect(() => {
        if (date !== null && date !== undefined && date !== '') {
            onChange(date)
        }
    }, [date, onChange])

    return (
        <FormControl>
            <TextField
                label="Bravos Network Model Date *"
                type="date"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                value={date}
                onChange={handleChange}
            />
        </FormControl>
    )
}
export default NMVPipelineBravosDateInputWidget
