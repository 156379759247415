import React from 'react'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid'
import {
    FormControl,
    Select,
    Menu,
    MenuItem,
    InputBase,
    Button,
} from '@material-ui/core'
import { FilterList, DragIndicator, SwapVert, GetApp } from '@material-ui/icons'

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
    filterSelectStyle: {
        display: 'flex',
        alignItems: 'center',
        color: '#d50037',
        fontSize: '0.8125rem',
        marginLeft: '.5rem',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    filterInputStyle: {
        marginLeft: '10px',
        backgroundColor: '#e5e5e5',
    },
    exportButton: {
        display: 'flex',
        alignItems: 'center',
        color: '#d50037',
        fontSize: '0.8125rem',
        marginLeft: '.5rem',
        backgroundColor: 'transparent',
        border: 'none',
        padding: '0',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    columnHeaderStyle: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        position: 'relative',
        paddingRight: '24px',
    },
    columnHeaderTextStyle: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flexGrow: 1,
    },
    resizeHandleStyle: {
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        width: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'col-resize',
        color: 'rgba(0, 0, 0, 0.54)',
        transition: 'color 0.3s',
        '&:hover': {
            color: 'rgba(0, 0, 0, 0.87)',
        },
        zIndex: 1,
    },
    dataGridStyle: {
        height: '600px',
        width: '100%',
    },
    dataGridColumnHeaderStyle: {
        padding: '0 0 0 16px',
    },
    dataGridColumnHeaderTitleStyle: {
        fontWeight: 'bold',
    },
    dataGridCellStyle: {
        paddingRight: '8px',
    },
}))

const TableFilter = ({ fields, filters, onFilterChange }) => {
    const classes = useStyles()

    return (
        <FormControl>
            <Select
                displayEmpty
                value=""
                disableUnderline
                renderValue={(selected) => (
                    <div className={classes.filterSelectStyle}>
                        <FilterList />
                        <span style={{ marginLeft: '8px' }}>
                            {selected || 'FILTERS'}
                        </span>
                    </div>
                )}
                IconComponent={() => null}
            >
                {fields.map((field) => (
                    <MenuItem key={field} value={field}>
                        <span>{field}</span>
                        <InputBase
                            placeholder={` Filter ${field}`}
                            value={filters[field] || ''}
                            onChange={(e) =>
                                onFilterChange(field, e.target.value)
                            }
                            className={classes.filterInputStyle} // Use the defined style
                            // required to disable some default functionality of Select that we don't want
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={(e) => e.stopPropagation()}
                        />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

const CustomToolbar = ({
    fields,
    filters,
    onFilterChange,
    filteredRows,
    tableName,
}) => {
    const classes = useStyles()

    const handleExportAll = () => {
        // Include column names as the first row
        const csvContent =
            'data:text/csv;charset=utf-8,' +
            [
                fields.join(','),
                ...filteredRows.map((row) => Object.values(row).join(',')),
            ].join('\n')
        const encodedUri = encodeURI(csvContent)
        const link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', `${tableName}.csv`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return (
        <GridToolbarContainer>
            <TableFilter
                fields={fields}
                filters={filters}
                onFilterChange={onFilterChange}
            />
            <GridToolbarColumnsButton />
            <GridToolbarDensitySelector />
            <Button
                onClick={handleExportAll}
                startIcon={<GetApp />}
                variant="text"
                className={classes.exportButton}
            >
                Export
            </Button>
        </GridToolbarContainer>
    )
}

const CustomHeader = ({ column, index, setColumns, onSort }) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const handleSort = (order) => {
        onSort(column.field, order)
        handleMenuClose()
    }

    const handleResize = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const startX = e.clientX
        const startWidth = column.width || 150

        const onMouseMove = (e) => {
            const newWidth = Math.max(startWidth + e.clientX - startX, 50)
            setColumns((prevColumns) => {
                const newColumns = [...prevColumns]
                newColumns[index] = {
                    ...newColumns[index],
                    width: newWidth,
                }
                return newColumns
            })
        }

        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove)
            document.removeEventListener('mouseup', onMouseUp)
            document.body.style.cursor = 'default'
        }

        document.addEventListener('mousemove', onMouseMove)
        document.addEventListener('mouseup', onMouseUp)
        document.body.style.cursor = 'col-resize'
    }

    return (
        <div className={classes.columnHeaderStyle}>
            <div
                className={classes.columnHeaderTextStyle}
                onClick={handleMenuOpen}
            >
                {column.headerName}
            </div>
            <div
                className={classes.resizeHandleStyle}
                onMouseDown={handleResize}
            >
                <DragIndicator style={{ fontSize: 20 }} />
            </div>
            <div>
                <SwapVert
                    onClick={handleMenuOpen}
                    style={{ cursor: 'pointer', marginTop: '20px' }}
                />
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={() => handleSort('asc')}>
                        Sort Ascending
                    </MenuItem>
                    <MenuItem onClick={() => handleSort('desc')}>
                        Sort Descending
                    </MenuItem>
                </Menu>
            </div>
        </div>
    )
}

const CustomDataGrid = ({
    rows,
    columns: initialColumns,
    getRowClassName,
    onCellEditCommit,
    onSelectionModelChange,
    filters: externalFilters = {},
    onFilterChange,
    columnVisibility,
    onColumnVisibilityChange,
    tableName,
}) => {
    const classes = useStyles() // Use the defined styles
    const [visibleColumns, setVisibleColumns] = useState(
        initialColumns.map((col) => col.field)
    )
    const [localFilters, setLocalFilters] = useState({})
    const [filteredRows, setFilteredRows] = useState(rows)
    const [columns, setColumns] = useState(initialColumns)
    const [selectionModel, setSelectionModel] = useState([])

    const handleColumnVisibilityChange = (field) => {
        onColumnVisibilityChange(field)
    }

    const handleFilterChange = (field, value) => {
        setLocalFilters((prevFilters) => ({
            ...prevFilters,
            [field]: value,
        }))
        onFilterChange(field, value)
    }

    useEffect(() => {
        const newVisibleColumns = initialColumns
            .filter((col) => columnVisibility[col.field] !== false) // Only include columns that are not explicitly set to false
            .map((col) => col.field) // Map to field names
        setVisibleColumns(newVisibleColumns)

        const newFilteredRows = rows.filter((row) => {
            return Object.entries(externalFilters).every(([field, value]) => {
                if (!value) return true
                // Ensure row[field] is not null or undefined before calling toString()
                return row[field]
                    ? row[field]
                          .toString()
                          .toLowerCase()
                          .includes(value.toLowerCase())
                    : false
            })
        })
        if (JSON.stringify(filteredRows) !== JSON.stringify(newFilteredRows)) {
            setFilteredRows(newFilteredRows)
        }
    }, [columnVisibility, initialColumns, externalFilters, rows, filteredRows])

    const handleSort = useCallback(
        (field, order) => {
            const sortedRows = [...filteredRows].sort((a, b) => {
                if (order === 'asc') {
                    return a[field] > b[field] ? 1 : -1
                } else {
                    return a[field] < b[field] ? 1 : -1
                }
            })
            setFilteredRows(sortedRows)
        },
        [filteredRows]
    )

    const resizableColumns = useMemo(() => {
        return columns.map((column, index) => ({
            ...column,
            width: column.width || 150,
            sortable: false,
            renderHeader: (params) => (
                <CustomHeader
                    column={params.colDef}
                    index={index}
                    setColumns={setColumns}
                    onSort={handleSort}
                />
            ),
            hide: !visibleColumns.includes(column.field),
        }))
    }, [columns, visibleColumns, handleSort])

    const handleSelectionModelChange = (newSelection) => {
        // Assuming you want to capture the most recent change from the new selection
        const latestSelection = newSelection.find(
            (item) => !selectionModel.includes(item) // Identify the new item that was added
        )

        if (latestSelection !== undefined) {
            // Set selection model to the most recently selected row
            setSelectionModel([latestSelection])
            onSelectionModelChange([latestSelection])
        }
    }

    return (
        <div className={classes.dataGridStyle}>
            <DataGrid
                rows={filteredRows}
                columns={resizableColumns}
                pageSize={100}
                rowsPerPageOptions={[25, 50, 100]}
                disableSelectionOnClick
                getRowClassName={getRowClassName}
                components={{
                    Toolbar: CustomToolbar,
                }}
                componentsProps={{
                    toolbar: {
                        fields: columns.map((col) => col.field),
                        filters: localFilters,
                        onFilterChange: handleFilterChange,
                        filteredRows: filteredRows,
                        tableName,
                    },
                }}
                checkboxSelection={true}
                onCellEditCommit={onCellEditCommit}
                onSelectionModelChange={handleSelectionModelChange}
                onColumnVisibilityChange={(params) => {
                    handleColumnVisibilityChange(params.field)
                }}
                disableRipple
                selectionModel={selectionModel}
                height="100%"
            />
        </div>
    )
}

export default CustomDataGrid
