import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useState, useEffect } from 'react'
import { getDAM3ScenariosByPipeline } from '../../api.js'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
    formControl: {
        display: 'flex',
    },
}))

const CreateNMVPipelineDialog = ({
    open,
    onClose,
    title,
    pipelineId,
    dam3PipelineId,
    showAlert,
    history,
}) => {
    const [pipelineName, setPipelineName] = useState('')
    const [bravosModelDate, setBravosModelDate] = useState('')
    const [bravosModelHour, setBravosModelHour] = useState('')
    const [ercotModelDate, setErcotModelDate] = useState('')
    const [ercotModelHour, setErcotModelHour] = useState('')
    const [scenarios, setScenarios] = useState([])
    const [scenario, setScenario] = useState('')

    const classes = useStyles()

    // Extract from title the branch_name and contingency
    const splitTitle = title.split('-')
    const branchName = splitTitle[0].split(' ')[3]
    const contingency = splitTitle[1].split(' ')[2]
    const pipeline_name = 'nmv_pipeline'

    const handleScenarioChange = (event) => {
        let scen = event.target.value
        setScenario(Number(scen))
    }

    useEffect(() => {
        if (
            dam3PipelineId !== null &&
            dam3PipelineId !== undefined &&
            scenarios.length === 0
        ) {
            const fetchDAM3Scenarios = async () => {
                const res = await getDAM3ScenariosByPipeline(dam3PipelineId)
                if (res.data !== null) {
                    setScenarios(res.data)
                }
            }
            fetchDAM3Scenarios()
        }
    }, [dam3PipelineId, scenarios])

    const createNMVPipeline = async (data) => {
        try {
            const res = await axios.post(
                `/pipeline/create?pipeline_name=${pipeline_name}`,
                data
            )
            history.push(`/${pipeline_name}/${res.data.pipeline_id}`)
            history.go(0)
        } catch (error) {
            if (error.response.status === 400) {
                showAlert(error.response.data)
            } else {
                showAlert(
                    'An unkown error occured.  Please try again momentarily.'
                )
            }
        }
    }

    const handleCreatePipeline = () => {
        // Guards against empty values
        if (pipelineName === '') {
            showAlert('Error: Please enter a pipeline name')
        } else if (bravosModelDate === '' || bravosModelHour === '') {
            showAlert(
                'Error: Please enter the desired date and hour for the bravos model'
            )
        } else if (ercotModelDate === '' || ercotModelHour === '') {
            showAlert(
                'Error: Please enter the desired date and hour for the ercot model (DAM)'
            )
        } else if (scenario === '') {
            showAlert(
                'Error: Please select a scenario for the dam post processing pipeline'
            )
        } else {
            createNMVPipeline({
                name: pipelineName,
                pipeline_settings: {
                    DAM3PipelineID: dam3PipelineId,
                    DAM3Scenario: scenario,
                },
                run_version: [
                    {
                        branchName: branchName,
                        contingency: contingency,
                        modelDateBravos: bravosModelDate,
                        modelHourBravos: bravosModelHour,
                        modelDateDAM: ercotModelDate,
                        modelHourDAM: ercotModelHour,
                        maxDepth: 3,
                    },
                ],
            })
        }
        onClose()
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                Create Network Model Visualizations Pipeline
            </DialogTitle>
            <DialogContent>
                <TextField
                    label="Pipeline name"
                    fullWidth
                    margin="normal"
                    value={pipelineName}
                    onChange={(e) => setPipelineName(e.target.value)}
                />
                <TextField
                    label="Bravos Network Model date"
                    type="date"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    value={bravosModelDate}
                    onChange={(e) => setBravosModelDate(e.target.value)}
                />
                <TextField
                    label="Bravos Network Model hour"
                    type="number"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    value={bravosModelHour}
                    onChange={(e) => setBravosModelHour(e.target.value)}
                />
                <TextField
                    label="ERCOT Network Model date"
                    type="date"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    value={ercotModelDate}
                    onChange={(e) => setErcotModelDate(e.target.value)}
                />
                <TextField
                    label="ERCOT Network Model hour"
                    type="number"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    value={ercotModelHour}
                    onChange={(e) => setErcotModelHour(e.target.value)}
                />
                <div>
                    {scenarios.length >= 1 ? (
                        <FormControl className={classes.formControl}>
                            <InputLabel id="scenario-select-label">
                                DAM 3 Scenario
                            </InputLabel>
                            <Select
                                labelId="scenario-select-label"
                                id="scenario-select"
                                value={scenario || ''}
                                onChange={handleScenarioChange}
                            >
                                {scenarios.map((scen) => (
                                    <MenuItem value={scen} key={scen}>
                                        {scen}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : (
                        <div>
                            Fetching DAM Post Processing Pipeline Id, please
                            wait...
                        </div>
                    )}
                </div>
                <p>
                    <br />
                    <i>
                        DAM 3 Id: {dam3PipelineId}, Branch: {branchName},
                        Contingency: {contingency}
                    </i>
                </p>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={handleCreatePipeline}
                    variant="contained"
                    color="primary"
                >
                    Create pipeline
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateNMVPipelineDialog
