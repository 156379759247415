import { createContext, useContext, useState } from 'react'

const MenuOpenContext = createContext()

export const MenuOpenProvider = ({ children }) => {
    const [open, setOpen] = useState(true)
    return (
        <MenuOpenContext.Provider value={{ open, setOpen }}>
            {children}
        </MenuOpenContext.Provider>
    )
}

export const useOpen = () => useContext(MenuOpenContext)
