import React from 'react'

const PNGViewerComponent = (props) => {
    const { visualization } = props

    // Destructure required data
    const { base64_data, subtitle } = visualization

    // Handle missing data
    if (!base64_data) {
        return (
            <div style={{ color: 'red', textAlign: 'center' }}>
                PNG visualization unavailable.
            </div>
        )
    }

    return (
        <div style={{ width: '100%', overflow: 'auto', textAlign: 'center' }}>
            <img
                src={`data:image/png;base64,${base64_data}`}
                alt="PNG Visualization"
                style={{
                    maxWidth: '100%',
                    height: 'auto',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
                }}
                onError={(e) => {
                    console.error('Failed to load PNG.')
                    e.target.src = '/path-to-fallback-image.png' // Add fallback image
                }}
            />
            <i> &emsp;&emsp;&emsp; {subtitle}</i>
        </div>
    )
}

export default PNGViewerComponent
