import { makeStyles, CircularProgress, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Drawer from '@material-ui/core/Drawer'
import TaskChart from './TaskChart'
import { Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CreateNMVPipelineDialog from './CreateNMVPipelineDialog'
import ListRelatedNMVPipelineTables from './ListRelatedNMVPipelineTables'
import { useState, useEffect } from 'react'
import { getDAM3IdFromMiniMasterId } from '../../api.js'

/* To allow column headers to wrap into the next line, instead of cropping the text */
const useStyle = makeStyles({
    rightSidebar: {
        width: '85%',
        flexShrink: 0, // Prevent shrinking of the Drawer
        '& .MuiDrawer-paper': {
            width: '85%',
        },
    },
    closeButton: {
        position: 'absolute',
        top: 10,
        left: 10,
        zIndex: 1, // Make sure it appears above any other content in the Drawer
    },
    drawerContent: {
        padding: '40px',
    },
    loadingIndicator: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-50px',
        marginLeft: '-50px',
        width: '100px',
        height: '100px',
    },
    actionButtons: {
        paddingLeft: '50px',
    },
})

const RightSidebarDrawer = (props) => {
    const classes = useStyle()
    const {
        showAlert,
        pipelineId,
        sidebarIsOpen,
        gotSidebarContent,
        sidebarContent,
        toggleSidebar,
        sidebarVizName,
        history,
    } = props

    const [openDialog, setOpenDialog] = useState(false)
    const handleOpenDialog = () => setOpenDialog(true)
    const handleCloseDialog = () => setOpenDialog(false)
    const [dam3PipelineId, setDam3PipelineId] = useState(null)

    useEffect(() => {
        if (sidebarVizName === 'DAM Contributions') {
            if (pipelineId && dam3PipelineId == null) {
                const fetchDam3Id = async () => {
                    try {
                        const id = await getDAM3IdFromMiniMasterId(pipelineId)
                        setDam3PipelineId(id)
                    } catch (error) {
                        console.error('Error fetching DAM3 Pipeline ID:', error)
                    }
                }
                fetchDam3Id()
            }
        }
    }, [sidebarVizName, pipelineId, dam3PipelineId])

    return (
        <Drawer
            anchor="right"
            pipelineId={pipelineId}
            showAlert={showAlert}
            open={sidebarIsOpen}
            onClose={toggleSidebar}
            className={classes.rightSidebar}
        >
            {sidebarIsOpen && gotSidebarContent === false && (
                <div className={classes.loadingIndicator}>
                    <CircularProgress color="primary" size={75} />
                    <div>LOADING...</div>
                </div>
            )}
            {sidebarIsOpen && gotSidebarContent && sidebarContent != null && (
                <div>
                    <IconButton
                        className={classes.closeButton}
                        onClick={toggleSidebar}
                    >
                        <CloseIcon />{' '}
                    </IconButton>
                    <div className={classes.drawerContent}>
                        <TaskChart
                            visualization={sidebarContent}
                            pipelineId={pipelineId}
                            showAlert={showAlert}
                        />
                    </div>
                    {sidebarVizName === 'DAM Contributions' &&
                        gotSidebarContent && (
                            <div className={classes.actionButtons}>
                                <Button
                                    size="large"
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={handleOpenDialog}
                                >
                                    Launch Network Model Visualizations Pipeline
                                </Button>
                                <CreateNMVPipelineDialog
                                    open={openDialog}
                                    onClose={handleCloseDialog}
                                    title={sidebarContent.title}
                                    pipelineId={pipelineId}
                                    showAlert={showAlert}
                                    history={history}
                                    dam3PipelineId={dam3PipelineId}
                                />
                                <ListRelatedNMVPipelineTables
                                    title={sidebarContent.title}
                                    showAlert={showAlert}
                                    history={history}
                                    dam3PipelineId={dam3PipelineId}
                                />
                            </div>
                        )}
                </div>
            )}
        </Drawer>
    )
}

export default RightSidebarDrawer
