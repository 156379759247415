import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl } from '@material-ui/core'
import { getDAM3ContingenciesByPipeline } from '../../../../api'
import { SearchableSelect } from '../../../../SearchableSelect/SearchableSelect'

const useStyles = makeStyles((theme) => ({
    formControl: {
        display: 'flex',
    },
}))

const DAMPostProcessingContingenciesWidget = (props) => {
    const { onChange, formContext, id } = props
    const [contingencies, setContingencies] = useState([])
    const [contingency, setContingency] = useState('')
    const [prevContingency, setPrevContingency] = useState('')
    const [fetchingContingencies, setFetchingContingencies] = useState(false)
    const [prevFormContext, setPrevFormContext] = useState('')

    // Extract index from id (e.g., "root_run_version_0_contingency" -> 0)
    const runVersionIndex = parseInt(id.split('_')[3], 10)

    const classes = useStyles()

    const handleChange = (event) => {
        setContingency(event.target.value)
    }

    useEffect(() => {
        if (
            contingency !== null &&
            contingency !== undefined &&
            contingency !== '' &&
            contingency !== prevContingency
        ) {
            onChange(contingency)
            setPrevContingency(contingency)
        }
    }, [contingency, onChange, prevContingency])

    useEffect(() => {
        const asyncFetchGetDAM3Contingencies = async (
            DAM3PipelineID,
            ercotDate,
            ercotHour
        ) => {
            if (
                DAM3PipelineID !== null &&
                DAM3PipelineID !== undefined &&
                ercotDate !== null &&
                ercotDate !== undefined &&
                ercotHour !== null &&
                ercotHour !== undefined &&
                fetchingContingencies === false
            ) {
                setFetchingContingencies(true)
                const res = await getDAM3ContingenciesByPipeline(
                    DAM3PipelineID,
                    ercotDate,
                    ercotHour
                )
                if (res.data !== null) {
                    // Transform the array of strings into an array of objects,
                    // to be able to use searchable select
                    setContingencies(
                        res.data.map((s) => ({
                            label: s,
                            value: s,
                        }))
                    )
                } else {
                    setContingencies([])
                }
                setFetchingContingencies(false)
            }
        }

        // We only reload contingencies if what changed was dam3ID, dam date or dam hour
        if (
            formContext &&
            formContext.pipeline_settings &&
            formContext.pipeline_settings.DAM3PipelineID &&
            formContext.run_version[runVersionIndex] &&
            formContext.run_version[runVersionIndex].modelDateDAM &&
            formContext.run_version[runVersionIndex].modelHourDAM
        ) {
            if (
                prevFormContext === '' ||
                (prevFormContext &&
                    prevFormContext.pipeline_settings &&
                    formContext.pipeline_settings.DAM3PipelineID &&
                    prevFormContext.run_version[runVersionIndex] &&
                    prevFormContext.run_version[runVersionIndex].modelDateDAM &&
                    prevFormContext.run_version[runVersionIndex].modelHourDAM &&
                    (prevFormContext.run_version[runVersionIndex]
                        .modelDateDAM !==
                        formContext.run_version[runVersionIndex].modelDateDAM ||
                        prevFormContext.run_version[runVersionIndex]
                            .modelHourDAM !==
                            formContext.run_version[runVersionIndex]
                                .modelHourDAM ||
                        prevFormContext.pipeline_settings.DAM3PipelineID !==
                            formContext.pipeline_settings.DAM3PipelineID))
            ) {
                setPrevFormContext(formContext)
                asyncFetchGetDAM3Contingencies(
                    formContext.pipeline_settings.DAM3PipelineID,
                    formContext.run_version[runVersionIndex].modelDateDAM,
                    formContext.run_version[runVersionIndex].modelHourDAM
                )
            }
        }
    }, [formContext, prevFormContext, runVersionIndex, fetchingContingencies])

    return (
        <div>
            {fetchingContingencies ? (
                <div>Loading contingencies, please wait...</div>
            ) : contingencies.length >= 1 ? (
                <FormControl className={classes.formControl}>
                    <SearchableSelect
                        key={contingency}
                        label="Contingency (Optional)"
                        value={contingency}
                        onChange={handleChange}
                        options={contingencies}
                        keyPropFn={(option) => option.value}
                        valuePropFn={(option) => option.value}
                        showAll
                        removeSelectionText
                        alwaysFocusTextFieldOnEnter
                        formControlProps={{ fullWidth: true }}
                    />
                </FormControl>
            ) : (
                <div>
                    Enter DAM 3 ID, Ercot Date and Hour to show available
                    branches and contingencies
                </div>
            )}
        </div>
    )
}
export default DAMPostProcessingContingenciesWidget
