import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useState, useEffect } from 'react'
import { getRelatedNMVPipelines } from '../../api.js'
import { convertToLocalTime } from '../../Utils/TimeUtil.js'

const useStyles = makeStyles((theme) => ({
    sectionHeader: {
        padding: '1.2rem 0',
        display: 'flex',
        flexWrap: 'nowrap',
        marginTop: '30px',
    },
    loadingIndicator: {
        marginTop: '100px',
        marginLeft: '200px',
        width: '60px',
        height: '60px',
    },
    clickableRow: {
        cursor: 'pointer',
        userSelect: 'none',
        '&:hover': {
            backgroundColor: theme.palette.action.hover, // Use theme's hover color
        },
    },
}))

const ListRelatedNMVPipelineTables = ({
    title,
    dam3PipelineId,
    showAlert,
    history,
}) => {
    const [listNMVPipelines, setListNMVPipelines] = useState([])
    const [fetchedNMVPipelines, setFetchedNMVPipelines] = useState(false)
    const [loading, setLoading] = useState(true)

    const classes = useStyles()

    // Extract from title the branch_name and contingency
    const splitTitle = title.split('-')
    const branchName = splitTitle[0].split(' ')[3]
    const contingency = splitTitle[1].split(' ')[2]
    const pipeline_name = 'nmv_pipeline'

    useEffect(() => {
        if (
            dam3PipelineId !== null &&
            dam3PipelineId !== undefined &&
            branchName !== '' &&
            contingency !== '' &&
            fetchedNMVPipelines === false
        ) {
            const fetchRelatedNMVPipelines = async () => {
                const res = await getRelatedNMVPipelines(
                    dam3PipelineId,
                    branchName,
                    contingency
                )
                setLoading(false)
                if (res.data !== null) {
                    setListNMVPipelines(res.data['nmv_pipelines'])
                    setFetchedNMVPipelines(true)
                }
            }
            fetchRelatedNMVPipelines()
        }
    }, [dam3PipelineId, branchName, contingency, fetchedNMVPipelines])

    return (
        <div>
            {loading && (
                <div className={classes.loadingIndicator}>
                    <CircularProgress color="primary" size={75} />
                </div>
            )}
            {fetchedNMVPipelines && listNMVPipelines.length === 0 && (
                <>
                    <p className={classes.sectionHeader}>
                        There are no related network model visualization
                        pipelines.
                    </p>
                </>
            )}
            {fetchedNMVPipelines && listNMVPipelines.length > 0 && (
                <>
                    <Typography
                        component="div"
                        className={classes.sectionHeader}
                    >
                        <Typography variant="h7" gutterBottom>
                            Related Network Model Visualization Pipelines
                        </Typography>
                    </Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Creator</TableCell>
                                <TableCell>Created Date</TableCell>
                                <TableCell>Completed Date</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listNMVPipelines.map((row) => (
                                <TableRow
                                    className={classes.clickableRow}
                                    key={row.id}
                                    onClick={(event) => {
                                        history.push(
                                            `/${pipeline_name}/${row.id}`
                                        )
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.created_by}</TableCell>
                                    <TableCell>
                                        {' '}
                                        {convertToLocalTime(row.created_date)}
                                    </TableCell>
                                    <TableCell>
                                        {' '}
                                        {row.completed_date
                                            ? convertToLocalTime(
                                                  row.completed_date
                                              )
                                            : 'Not Completed'}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            )}
        </div>
    )
}

export default ListRelatedNMVPipelineTables
