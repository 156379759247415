import { useEffect, useState } from 'react'
import { FormControl, TextField } from '@material-ui/core'

const NMVPipelineErcotHourInputWidget = (props) => {
    const { onChange, formContext, id } = props
    const [hour, setHour] = useState('')
    // Extract index from id (e.g., "root_run_version_0_generator" -> 0)
    const runVersionIndex = parseInt(id.split('_')[3], 10)

    const handleChange = (event) => {
        setHour(event.target.value)
    }

    useEffect(() => {
        if (
            formContext &&
            formContext.run_version &&
            formContext.run_version[0] &&
            formContext.run_version[0].modelHourDAM &&
            hour === ''
        ) {
            const timeout = setTimeout(
                () => {
                    setHour(formContext.run_version[0].modelHourDAM)
                },
                runVersionIndex * 9 + 9
            )

            return () => clearTimeout(timeout)
        }
    }, [formContext, hour, runVersionIndex])

    useEffect(() => {
        if (hour !== null && hour !== undefined && hour !== '') {
            onChange(hour)
        }
    }, [hour, onChange])

    return (
        <FormControl>
            <TextField
                label="Ercot Network Model Hour *"
                type="number"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                value={hour}
                onChange={handleChange}
            />
        </FormControl>
    )
}
export default NMVPipelineErcotHourInputWidget
