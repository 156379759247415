export const validate = (formData, errors) => {
    const today = new Date().toISOString().split('T')[0]
    const deliveryDate =
        formData.auctionTransactionsToolPipelineSettings?.deliveryDate

    if (deliveryDate && deliveryDate > today) {
        errors.auctionTransactionsToolPipelineSettings.deliveryDate.addError(
            'Delivery date cannot be in the future.'
        )
    }

    return errors
}
