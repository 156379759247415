import { Grid, Typography, makeStyles, Button } from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid'
import {
    addIdsToRows,
    preferredOrder,
    generateColumnsWithCustomColors,
    addCommasToNumbers,
    getSidebarData,
} from '../../../Utils/DataTableUtil'

/* To allow column headers to wrap into the next line, instead of cropping the text */
const useStyle = makeStyles({
    root: {
        '& .MuiDataGrid-columnHeaderTitle': {
            overflow: 'visible',
            lineHeight: '1.43rem',
            whiteSpace: 'normal',
        },
    },
    dataTableColumnColor0: {
        backgroundColor: '#b3e0ff',
    },
    dataTableColumnColor1: {
        backgroundColor: '#b3ffb3',
    },
    dataTableColumnColor2: {
        backgroundColor: '#c9c9ff',
    },
    dataTableColumnColor3: {
        backgroundColor: '#ffcc99',
    },
    dataTableColumnColor4: {
        backgroundColor: '#d9b3ff',
    },
    dataTableColumnColor5: {
        backgroundColor: '#c2f0c2',
    },
    dataTableColumnColor6: {
        backgroundColor: '#ffb380',
    },
    dataTableColumnColor7: {
        backgroundColor: '#ffeb99',
    },
    dataTableColumnColor8: {
        backgroundColor: '#b3e6cc',
    },
    dataTableColumnColor9: {
        backgroundColor: '#ffccff',
    },
})

const DataTable = (props) => {
    const classes = useStyle()
    const { toggleSidebar, updateSidebarContent, updateSidebarVizName } = props

    const openSidebarVisualization = async (data, vizName) => {
        updateSidebarVizName(vizName)
        toggleSidebar()
        let sidebarData = await getSidebarData(data.field, data.row)
        updateSidebarContent(sidebarData.data)
    }

    let raw_data = props.visualization.data
    let reordered_data = []
    if ('col_order' in props.visualization) {
        if (props.visualization.col_order !== null) {
            for (let i = 0; i < raw_data.length; i++) {
                let row = raw_data[i]
                let new_row = preferredOrder(row, props.visualization.col_order)
                reordered_data.push(new_row)
            }
            raw_data = reordered_data
        }
    }

    let column_colors = []
    if ('column_colors' in props.visualization) {
        if (props.visualization.column_colors !== null) {
            column_colors = props.visualization.column_colors
        }
    }
    if ('add_commas_to_numbers' in props.visualization) {
        if (props.visualization.add_commas_to_numbers === true) {
            raw_data = addCommasToNumbers(raw_data)
        }
    }
    let columns = []
    if (raw_data.length > 0) {
        columns = generateColumnsWithCustomColors(
            raw_data,
            column_colors,
            classes
        )
        if ('has_sidebar_visualization' in props.visualization) {
            if (props.visualization.has_sidebar_visualization === true) {
                if ('sidebar_viz_column_name' in props.visualization) {
                    let new_col_name =
                        props.visualization.sidebar_viz_column_name
                    let column_to_prepend = {
                        field: new_col_name,
                        headerName: new_col_name,
                        width: 200,
                        editable: false,
                        renderCell: (params) => (
                            <strong>
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="secondary"
                                    style={{ marginLeft: 16 }}
                                    tabIndex={params.hasFocus ? 0 : -1}
                                    onClick={() =>
                                        openSidebarVisualization(
                                            params,
                                            new_col_name
                                        )
                                    }
                                >
                                    Show
                                </Button>
                            </strong>
                        ),
                    }
                    columns.unshift(column_to_prepend)
                }
            }
        }
    }

    let data = addIdsToRows(raw_data)

    let title = ''
    if ('title' in props.visualization) {
        title = props.visualization.title
    }
    let subtitles = []
    if ('subtitle' in props.visualization) {
        if (props.visualization.subtitle !== null) {
            subtitles = props.visualization.subtitle
        }
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
            sx={{ marginTop: 25, marginBottom: 10 }}
        >
            <Grid item xs={11}>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                <Typography variant="h6">{title}</Typography>
                {subtitles.length > 0 &&
                    subtitles.map((elem, index) => (
                        <Typography variant="subtitle2">{elem}</Typography>
                    ))}
            </Grid>
            <Grid item xs={12}>
                {data.length > 0 && (
                    <DataGrid
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                        columnVisibilityModel={{
                            id: false,
                        }}
                        className={classes.root}
                        rows={data}
                        columns={columns}
                        autoHeight={true}
                        headerHeight={110}
                        pageSize={data.length}
                        density={'compact'}
                        rowsPerPageOptions={[data.length]}
                        fullWidth={true}
                    />
                )}
            </Grid>
            {data.length === 0 && (
                <Grid item xs={8}>
                    <Typography variant="body1">
                        No data to show for this visualization.
                    </Typography>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                </Grid>
            )}
        </Grid>
    )
}

export default DataTable
