import {
    Typography,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Box,
    Collapse,
} from '@material-ui/core'
import clsx from 'clsx'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Build from '@material-ui/icons/Build'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import TuneIcon from '@material-ui/icons/Tune'
import SettingsIcon from '@material-ui/icons/Settings'
import AssessmentIcon from '@material-ui/icons/Assessment'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import SettingsInputSvideoIcon from '@material-ui/icons/SettingsInputSvideo'
import CachedIcon from '@material-ui/icons/Cached'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh'
import AssignmentTurnedInRoundedIcon from '@material-ui/icons/AssignmentTurnedInRounded'
import BugReportIcon from '@material-ui/icons/BugReport'
import DescriptionIcon from '@material-ui/icons/Description'
import TimelineIcon from '@material-ui/icons/Timeline'
import { withRouter } from 'react-router'
import { ReactComponent as BravosLogo } from '../assets/BravosFull.svg'
import { useAuth0 } from '@auth0/auth0-react'
import { useOpen } from './MenuOpenContext'
import { useState } from 'react'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

const drawerWidth = 325

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& .MuiDrawer-paperAnchorDockedLeft': {
            border: 'none',
            backgroundColor: theme.palette.primary.main,
            boxShadow: '0 4px 18px 3px rgba(0,0,0,0.23)',
            justifyContent: 'space-between',
        },
        '& .MuiListItemIcon-root': {
            paddingLeft: theme.spacing(1),
            marginRight: theme.spacing(-3),
        },
        '& .MuiListItem-root': {
            '&:hover': {
                background: '#e9003c',
                '&> *': {
                    color: theme.palette.secondary.main,
                },
            },
        },
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7) + 1,
        },
    },
    paper: {
        background: theme.palette.primary.main,
    },
    content: {
        flexGrow: 1,
        height: '98vh',
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    clickableHeader: {
        display: 'flex',
        flexDirection: 'columns',
        alignItems: 'center',
        cursor: 'pointer',
        userSelect: 'none',
        marginLeft: theme.spacing(1),
    },
    list: {
        paddingBottom: '1.1rem',
        '& > * ': {
            color: '#eceff1',
        },
    },
    copyright: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    menuButton: {
        alignItems: 'center',
    },
    hide: {
        display: 'None',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}))

const Layout = (props) => {
    const { children, history } = props
    const classes = useStyles()
    const date = new Date()
    const year = date.getFullYear()
    const { logout } = useAuth0()
    const { open, setOpen } = useOpen()

    const [damListExpanded, setDamListExpanded] = useState(false)
    const [auctionListExpanded, setAuctionListExpanded] = useState(false)
    const [masterListExpanded, setMasterListExpanded] = useState(false)
    const [toolListExpanded, setToolListExpanded] = useState(false)
    const [settingsListExpanded, setSettingsListExpanded] = useState(false)

    const toggleDrawer = () => {
        setOpen(!open)
    }

    const toggleDamListExpanded = () => {
        setDamListExpanded(!damListExpanded)
    }

    const toggleAuctionListExpanded = () => {
        setAuctionListExpanded(!auctionListExpanded)
    }

    const toggleMasterListExpanded = () => {
        setMasterListExpanded(!masterListExpanded)
    }

    const toggleToolListExpanded = () => {
        setToolListExpanded(!toolListExpanded)
    }

    const toggleSettingsListExpanded = () => {
        setSettingsListExpanded(!settingsListExpanded)
    }

    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        })
    }

    return (
        <div className={classes.root}>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
                open={open}
            >
                <div>
                    {!open && (
                        <IconButton
                            color="secondary"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            className={clsx(classes.menuButton, {
                                [classes.hide]: open,
                            })}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    {open && (
                        <div
                            style={{
                                display: 'flex', // Aligns children horizontally
                                alignItems: 'center', // Vertically aligns items
                                justifyContent: 'space-between', // Adds space between elements
                            }}
                        >
                            <Box
                                className={classes.clickableHeader}
                                onClick={() => history.push('/')}
                            >
                                <BravosLogo
                                    style={{
                                        height: '100px',
                                        width: '200px',
                                        marginRight: '1.2rem',
                                        marginLeft: '1rem',
                                    }}
                                />
                            </Box>
                            <IconButton onClick={toggleDrawer}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </div>
                    )}
                    {open && (
                        <List>
                            <ListItem
                                onClick={toggleDamListExpanded}
                                classes={{
                                    root: classes.list,
                                }}
                            >
                                <ListItemIcon>
                                    <DescriptionIcon />
                                </ListItemIcon>
                                <ListItemText primary="DAM Pipelines" />
                                {damListExpanded ? (
                                    <ExpandLess />
                                ) : (
                                    <ExpandMore />
                                )}
                            </ListItem>
                            <Collapse
                                in={damListExpanded}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List component="div" disablePadding>
                                    <ListItem
                                        button
                                        classes={{
                                            root: classes.list, // class name, e.g. `classes-nesting-root-x`
                                        }}
                                        className={classes.nested}
                                        onClick={() =>
                                            history.push('/mno_pipeline')
                                        }
                                    >
                                        <ListItemIcon>
                                            <DescriptionIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="MNO Pipeline" />
                                    </ListItem>

                                    <ListItem
                                        button
                                        classes={{
                                            root: classes.list, // class name, e.g. `classes-nesting-root-x`
                                        }}
                                        className={classes.nested}
                                        onClick={() =>
                                            history.push(
                                                '/data_transformation_pipeline'
                                            )
                                        }
                                    >
                                        <ListItemIcon>
                                            <TrendingUpIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Data Transformations Pipeline (D/W)" />
                                    </ListItem>

                                    <ListItem
                                        button
                                        classes={{
                                            root: classes.list, // class name, e.g. `classes-nesting-root-x`
                                        }}
                                        className={classes.nested}
                                        onClick={() =>
                                            history.push(
                                                '/data_extraction_pipeline'
                                            )
                                        }
                                    >
                                        <ListItemIcon>
                                            <AssessmentIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Data Extraction Pipeline" />
                                    </ListItem>

                                    <ListItem
                                        button
                                        classes={{
                                            root: classes.list, // class name, e.g. `classes-nesting-root-x`
                                        }}
                                        className={classes.nested}
                                        onClick={() =>
                                            history.push(
                                                '/dam_post_processing_pipeline'
                                            )
                                        }
                                    >
                                        <ListItemIcon>
                                            <AssessmentIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="DAM Post Processing Pipeline" />
                                    </ListItem>
                                </List>
                            </Collapse>

                            <ListItem
                                onClick={toggleAuctionListExpanded}
                                classes={{
                                    root: classes.list,
                                }}
                            >
                                <ListItemIcon>
                                    <AttachMoneyIcon />
                                </ListItemIcon>
                                <ListItemText primary="Auction Pipelines" />
                                {auctionListExpanded ? (
                                    <ExpandLess />
                                ) : (
                                    <ExpandMore />
                                )}
                            </ListItem>
                            <Collapse
                                in={auctionListExpanded}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List component="div" disablePadding>
                                    <ListItem
                                        button
                                        classes={{
                                            root: classes.list, // class name, e.g. `classes-nesting-root-x`
                                        }}
                                        className={classes.nested}
                                        onClick={() =>
                                            history.push('/auction_pipeline')
                                        }
                                    >
                                        <ListItemIcon>
                                            <AttachMoneyIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Auction Pipeline" />
                                    </ListItem>

                                    <ListItem
                                        button
                                        classes={{
                                            root: classes.list, // class name, e.g. `classes-nesting-root-x`
                                        }}
                                        className={classes.nested}
                                        onClick={() =>
                                            history.push('/auction_ii_pipeline')
                                        }
                                    >
                                        <ListItemIcon>
                                            <AttachMoneyIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Auction II Pipeline" />
                                    </ListItem>

                                    <ListItem
                                        button
                                        classes={{
                                            root: classes.list, // class name, e.g. `classes-nesting-root-x`
                                        }}
                                        className={classes.nested}
                                        onClick={() =>
                                            history.push(
                                                '/auction_model_pipeline'
                                            )
                                        }
                                    >
                                        <ListItemIcon>
                                            <AttachMoneyIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Auction Model Pipeline" />
                                    </ListItem>
                                </List>
                            </Collapse>

                            <ListItem
                                onClick={toggleMasterListExpanded}
                                classes={{
                                    root: classes.list,
                                }}
                            >
                                <ListItemIcon>
                                    <BrightnessHighIcon />
                                </ListItemIcon>
                                <ListItemText primary="Master Pipelines" />
                                {masterListExpanded ? (
                                    <ExpandLess />
                                ) : (
                                    <ExpandMore />
                                )}
                            </ListItem>
                            <Collapse
                                in={masterListExpanded}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List component="div" disablePadding>
                                    <ListItem
                                        button
                                        classes={{
                                            root: classes.list, // class name, e.g. `classes-nesting-root-x`
                                        }}
                                        className={classes.nested}
                                        onClick={() =>
                                            history.push(
                                                '/master_model_data_prep_pipeline'
                                            )
                                        }
                                    >
                                        <ListItemIcon>
                                            <BrightnessHighIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Master Model Data Prep Pipeline" />
                                    </ListItem>

                                    <ListItem
                                        button
                                        classes={{
                                            root: classes.list, // class name, e.g. `classes-nesting-root-x`
                                        }}
                                        className={classes.nested}
                                        onClick={() =>
                                            history.push(
                                                '/mini_master_pipeline'
                                            )
                                        }
                                    >
                                        <ListItemIcon>
                                            <BrightnessHighIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Mini Master Pipeline" />
                                    </ListItem>

                                    <ListItem
                                        button
                                        classes={{
                                            root: classes.list, // class name, e.g. `classes-nesting-root-x`
                                        }}
                                        className={classes.nested}
                                        onClick={() =>
                                            history.push(
                                                '/portfolio_stats_pipeline'
                                            )
                                        }
                                    >
                                        <ListItemIcon>
                                            <AssignmentTurnedInRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Portfolio Stats Pipeline" />
                                    </ListItem>
                                </List>
                            </Collapse>

                            <ListItem
                                onClick={toggleToolListExpanded}
                                classes={{
                                    root: classes.list,
                                }}
                            >
                                <ListItemIcon>
                                    <Build />
                                </ListItemIcon>
                                <ListItemText primary="Tools" />
                                {masterListExpanded ? (
                                    <ExpandLess />
                                ) : (
                                    <ExpandMore />
                                )}
                            </ListItem>
                            <Collapse
                                in={toolListExpanded}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List component="div" disablePadding>
                                    <ListItem
                                        button
                                        classes={{
                                            root: classes.list,
                                        }}
                                        className={classes.nested}
                                        onClick={() =>
                                            history.push('/scraper_stats')
                                        }
                                    >
                                        <ListItemIcon>
                                            <BugReportIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Scraper Stats" />
                                    </ListItem>

                                    <ListItem
                                        button
                                        classes={{
                                            root: classes.list,
                                        }}
                                        className={classes.nested}
                                        onClick={() =>
                                            history.push('/lp_stats')
                                        }
                                    >
                                        <ListItemIcon>
                                            <BugReportIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="LP Stats" />
                                    </ListItem>

                                    <ListItem
                                        button
                                        classes={{
                                            root: classes.list,
                                        }}
                                        className={classes.nested}
                                        onClick={() =>
                                            history.push('/nmv_pipeline')
                                        }
                                    >
                                        <ListItemIcon>
                                            <TimelineIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Network Model Visualizations" />
                                    </ListItem>

                                    <ListItem
                                        button
                                        classes={{
                                            root: classes.list,
                                        }}
                                        className={classes.nested}
                                        onClick={() =>
                                            history.push(
                                                '/auction_transactions_tool_pipeline'
                                            )
                                        }
                                    >
                                        <ListItemIcon>
                                            <Build />
                                        </ListItemIcon>
                                        <ListItemText primary="Auction Transactions Tool" />
                                    </ListItem>
                                </List>
                            </Collapse>

                            <ListItem
                                onClick={toggleSettingsListExpanded}
                                classes={{
                                    root: classes.list,
                                }}
                            >
                                <ListItemIcon>
                                    <TuneIcon />
                                </ListItemIcon>
                                <ListItemText primary="Pipeline Settings" />
                                {settingsListExpanded ? (
                                    <ExpandLess />
                                ) : (
                                    <ExpandMore />
                                )}
                            </ListItem>
                            <Collapse
                                in={settingsListExpanded}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List component="div" disablePadding>
                                    <ListItem
                                        button
                                        classes={{
                                            root: classes.list, // class name, e.g. `classes-nesting-root-x`
                                        }}
                                        className={classes.nested}
                                        onClick={() =>
                                            history.push(
                                                '/global_configurations'
                                            )
                                        }
                                    >
                                        <ListItemIcon>
                                            <SettingsIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Global Configurations (RSY)" />
                                    </ListItem>

                                    <ListItem
                                        button
                                        classes={{
                                            root: classes.list, // class name, e.g. `classes-nesting-root-x`
                                        }}
                                        className={classes.nested}
                                        onClick={() =>
                                            history.push('/run_configurations')
                                        }
                                    >
                                        <ListItemIcon>
                                            <TuneIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Run Configurations (MNO)" />
                                    </ListItem>

                                    <ListItem
                                        button
                                        classes={{
                                            root: classes.list, // class name, e.g. `classes-nesting-root-x`
                                        }}
                                        className={classes.nested}
                                        onClick={() =>
                                            history.push('/run_modifications')
                                        }
                                    >
                                        <ListItemIcon>
                                            <SettingsInputSvideoIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Run Modifications (TUX)" />
                                    </ListItem>

                                    <ListItem
                                        button
                                        classes={{
                                            root: classes.list, // class name, e.g. `classes-nesting-root-x`
                                        }}
                                        className={classes.nested}
                                        onClick={() =>
                                            history.push(
                                                '/custom_auction_modifications'
                                            )
                                        }
                                    >
                                        <ListItemIcon>
                                            <CachedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Custom Auction Modifications (CAM)" />
                                    </ListItem>
                                </List>
                            </Collapse>

                            <ListItem
                                button
                                classes={{
                                    root: classes.list, // class name, e.g. `classes-nesting-root-x`
                                }}
                                className={classes.listItem}
                                style={{ marginBottom: '1rem' }}
                                onClick={handleLogout}
                            >
                                <ListItemIcon>
                                    <ExitToAppOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Sign Out'} />
                            </ListItem>
                        </List>
                    )}
                </div>
                {open && (
                    <Box className={classes.copyright}>
                        <Typography
                            variant="subtitle1"
                            style={{ padding: '2rem 3rem', color: '#F5F5F5' }}
                        >
                            © {year} Bravos. All rights reserved.
                        </Typography>
                    </Box>
                )}
            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                {children}
            </main>
        </div>
    )
}

export default withRouter(Layout)
