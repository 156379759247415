import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl } from '@material-ui/core'
import { getDAM3LoadsByPipeline } from '../../../../api'
import { SearchableSelect } from '../../../../SearchableSelect/SearchableSelect'

const useStyles = makeStyles((theme) => ({
    formControl: {
        display: 'flex',
    },
}))

const DAMPostProcessingLoadsWidget = (props) => {
    const { onChange, formContext, id } = props
    const [loads, setLoads] = useState([])
    const [load, setLoad] = useState('')
    const [autofilledLoad, setAutofilledLoad] = useState(false)
    const [prevLoad, setPrevLoad] = useState('')
    const [fetchingLoads, setFetchingloads] = useState(false)
    const [prevFormContext, setPrevFormContext] = useState('')

    // Extract index from id (e.g., "root_run_version_0_load" -> 0)
    const runVersionIndex = parseInt(id.split('_')[3], 10)

    const classes = useStyles()

    const handleChange = (event) => {
        setLoad(event.target.value)
    }

    useEffect(() => {
        if (
            load !== null &&
            load !== undefined &&
            load !== '' &&
            load !== prevLoad
        ) {
            onChange(load)
            setPrevLoad(load)
        }
    }, [load, onChange, prevLoad])

    useEffect(() => {
        const asyncFetchGetDAM3Loads = async (
            DAM3PipelineID,
            ercotDate,
            ercotHour
        ) => {
            if (
                DAM3PipelineID !== null &&
                DAM3PipelineID !== undefined &&
                ercotDate !== null &&
                ercotDate !== undefined &&
                ercotHour !== null &&
                ercotHour !== undefined
            ) {
                setFetchingloads(true)
                const res = await getDAM3LoadsByPipeline(
                    DAM3PipelineID,
                    ercotDate,
                    ercotHour
                )
                setFetchingloads(false)
                if (res.data !== null) {
                    // Transform the array of strings into an array of objects,
                    // to be able to use searchable select
                    setLoads(
                        res.data.map((s) => ({
                            label: s,
                            value: s,
                        }))
                    )
                }
            } else {
                setLoads([])
            }
        }

        // We only refetch loads if what changed was dam3ID, dam date or dam hour
        if (
            formContext &&
            formContext.pipeline_settings &&
            formContext.pipeline_settings.DAM3PipelineID &&
            formContext.run_version[runVersionIndex] &&
            formContext.run_version[runVersionIndex].modelDateDAM &&
            formContext.run_version[runVersionIndex].modelHourDAM
        ) {
            if (
                prevFormContext === '' ||
                (prevFormContext &&
                    prevFormContext.pipeline_settings &&
                    formContext.pipeline_settings.DAM3PipelineID &&
                    prevFormContext.run_version[runVersionIndex] &&
                    prevFormContext.run_version[runVersionIndex].modelDateDAM &&
                    prevFormContext.run_version[runVersionIndex].modelHourDAM &&
                    (prevFormContext.run_version[runVersionIndex]
                        .modelDateDAM !==
                        formContext.run_version[runVersionIndex].modelDateDAM ||
                        prevFormContext.run_version[runVersionIndex]
                            .modelHourDAM !==
                            formContext.run_version[runVersionIndex]
                                .modelHourDAM ||
                        prevFormContext.pipeline_settings.DAM3PipelineID !==
                            formContext.pipeline_settings.DAM3PipelineID))
            ) {
                setPrevFormContext(formContext)
                asyncFetchGetDAM3Loads(
                    formContext.pipeline_settings.DAM3PipelineID,
                    formContext.run_version[runVersionIndex].modelDateDAM,
                    formContext.run_version[runVersionIndex].modelHourDAM
                )
            }
        }
    }, [formContext, prevFormContext, runVersionIndex])

    // Autofill load name with value from run_version 0 if user hasn't selected any
    useEffect(() => {
        if (
            formContext &&
            formContext.run_version &&
            formContext.run_version[0] &&
            formContext.run_version[0].loadName &&
            formContext.run_version[0].loadName !== '' &&
            // eslint-disable-next-line
            loads != [] &&
            load === ''
        ) {
            const timeout = setTimeout(
                () => {
                    if (!autofilledLoad) {
                        setAutofilledLoad(true)
                        setLoad(formContext.run_version[0].loadName)
                    }
                },
                15 + runVersionIndex * 15
            )

            return () => clearTimeout(timeout)
        }
    }, [loads, load, autofilledLoad, formContext, runVersionIndex])

    return (
        <div>
            {fetchingLoads ? (
                <div>
                    <br />
                    Getting load names...
                </div>
            ) : loads.length >= 1 ? (
                <FormControl className={classes.formControl}>
                    <SearchableSelect
                        key={load}
                        label="Load Name (Optional)"
                        value={load}
                        onChange={handleChange}
                        options={loads}
                        keyPropFn={(option) => option.value}
                        valuePropFn={(option) => option.value}
                        showAll
                        removeSelectionText
                        alwaysFocusTextFieldOnEnter
                        formControlProps={{ fullWidth: true }}
                    />
                </FormControl>
            ) : (
                <div>
                    <br />
                    No loads found
                </div>
            )}
        </div>
    )
}
export default DAMPostProcessingLoadsWidget
